import React, { FC } from 'react'
import parseHtml from 'html-react-parser'
import { useTheme } from 'emotion-theming'

import Link from '../../../ui/Link'
import Grid from '../../../ui/Grid'
import GridItem from '../../../ui/GridItem'
import PageHeading from '../../PageHeading'
import useIntl from '../../../states/useIntl'
import { CompactCTAButton } from '../../CTAButton'
import ContainerFixed from '../../../ui/ContainerFixed'

type Props = {
  workPagePath: string
}

const Hero: FC<Props> = ({ workPagePath }) => {
  const { t } = useIntl()
  const theme = useTheme()

  return (
    <ContainerFixed>
      <Grid
        py={5}
        alignItems="flex-end"
      >
        <GridItem
          pt={4}
          width={[1, 1, 3 / 4]}
        >
          <PageHeading
            sx={{
              color: 'text',
              '& em': {
                fontStyle: 'normal',
                WebkitTextFillColor: 'transparent',
                WebkitTextStrokeWidth: '1px',
                WebkitTextStrokeColor: theme.colors.text,
              },
            }}
          >
            {
              parseHtml(
                t('hero.valueProposition'),
              )
            }
          </PageHeading>

          <Link to={workPagePath}>
            <CompactCTAButton
              as="div"
              containerProps={{ mt: 3 }}
              size="large"
            >
              {t('hero.ctaLabel')}
            </CompactCTAButton>
          </Link>
        </GridItem>
      </Grid>
    </ContainerFixed>
  )
}

export default Hero

import React, { FC, ComponentProps as CP, useState } from 'react'

import Img from 'gatsby-image'
import { Box, Flex } from 'rebass'
import styled from '@emotion/styled'
import { variant } from 'styled-system'
import useInterval from '@use-it/interval'
import { graphql, useStaticQuery } from 'gatsby'

import BorderBox from '../../BorderBox'
import { PhoneCarouselQuery } from '../../../../graphql-types'
import phoneClockUrl from '../../../content/images/icons/phone_clock.svg'
import phoneStatusbarUrl from '../../../content/images/icons/phone_statusbar.svg'

type Props = CP<typeof BorderBox> & {
  variant?: 'flat' | 'embossed' | Array<'flat' | 'embossed'>,
}

const Frame = styled(BorderBox)`
  ${variant({
    variants: {
      flat: {
        boxShadow: 'none',
      },
      embossed: {
        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.05), -10px -10px 20px rgba(255, 255, 255, 0.6)',
      },
    },
  })}
`

const AnimatedFlex = styled(Flex)`
  will-change: transform;
  transition: transform 1s ease;
`

const PhoneCarousel: FC<Props> = ({ ...props }) => {
  const { allFile: { images } }: PhoneCarouselQuery = useStaticQuery(graphql`
    query PhoneCarousel {
      allFile(
        filter: {
          relativeDirectory: {
            eq: "images/carousel"
          }
        },
        sort: {
          order: ASC,
          fields: name
        }
      ) {
        images: nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)

  const [ index, setIndex ] = useState(0)

  useInterval(() => {
    setIndex((prevIndex) => {
      if (prevIndex < images.length - 1) {
        return prevIndex + 1
      } else {
        return 0
      }
    })
  }, 3000)

  return (
    <Frame {...props as any}> {/* TODO: 😭 */}
      <Flex
        height="100%"
        flexDirection="column"
      >
        <Box
          padding={4}
          display={['none', 'none', 'block']}
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
          >
            <img
              src={phoneClockUrl}
              alt="phone clock"
            />
            <img
              src={phoneStatusbarUrl}
              alt="phone statusbar"
            />
          </Flex>
        </Box>

        <Box
          flex={1}
          alignSelf="flex-start"
        >
          <AnimatedFlex
            height="100%"
            flexWrap="nowrap"
            alignItems="center"
            css={{ transform: `translateX(-${(100 / images.length) * index}%)` }}
          >
            {
              images.map((image) => (
                <BorderBox
                  ml={[3, 3, 4]}
                  overflow="hidden"
                  borderRadius={[25]}
                  width={[250, 250, 320]}
                  height={[445, 445, 570]}
                  key={image.childImageSharp!.fluid!.src!}
                >
                  <Img
                    css={{ height: '100%' }}
                    {...{ objectFit: 'contain' } as any}
                    fluid={image.childImageSharp!.fluid! as any}
                  />
                </BorderBox>
              ))
            }
          </AnimatedFlex>
        </Box>
      </Flex>
    </Frame>
  )
}

export default PhoneCarousel

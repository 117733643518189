import React, { FC } from 'react'

import { Box, Flex } from 'rebass'
import styled from '@emotion/styled'
import parseHtml from 'html-react-parser'

import Link from '../../../ui/Link'
import Grid from '../../../ui/Grid'
import SrOnly from '../../../ui/SrOnly'
import Section from '../../../ui/Section'
import GridItem from '../../../ui/GridItem'
import PhoneCarousel from './PhoneCarousel'
import useIntl from '../../../states/useIntl'
import { CompactCTAButton } from '../../CTAButton'
import ContainerFixed from '../../../ui/ContainerFixed'
import SectionHeading from '../../../ui/SectionHeading'

import OrmoBadge from './OrmoBadge'

type Props = {
  aboutPagePath: string
}

const BadgeContainer = styled(Flex)`
  z-index: 1;
  position: relative;
`

const AboutUs: FC<Props> = ({ aboutPagePath }) => {
  const { t } = useIntl()

  return (
    <>
      <BadgeContainer
        px={3}
        mb={-5}
        mx="auto"
        maxWidth={1680}
        justifyContent="flex-end"
      >
        <OrmoBadge
          to={aboutPagePath}
          title={t('section.aboutUs.title')}
        />
      </BadgeContainer>
      <ContainerFixed>
        <Section>
          <Grid
            alignItems={['initial', 'initial', 'center']}
            flexDirection={['column-reverse', 'column-reverse', 'row']}
          >
            <GridItem
              maxWidth="100%"
              flex={['initial', 'initial', 1]}
            >
              <SectionHeading
                color="text"
              >
                {t('section.aboutUs.title')}
              </SectionHeading>

              <Box
                mb={4}
                color="text"
                fontSize={0}
              >
                {parseHtml(t('section.aboutUs.content'))}
              </Box>

              <Link to={aboutPagePath}>
                <CompactCTAButton as="div">
                  {t('section.aboutUs.ctaLabel')}
                  <SrOnly>
                    &nbsp;{t('section.aboutUs.ctaSrText')}
                  </SrOnly>
                </CompactCTAButton>
              </Link>
            </GridItem>

            <GridItem
              px={[0, 0, 3]}
              maxWidth="100%"
            >
              <PhoneCarousel
                overflow="hidden"
                ml={[0, 0, 6, 7]}
                height={[445, 445, 960]}
                width={['auto', 'auto', 450]}
                variant={['flat', 'flat', 'embossed']}
                borderRadius={['initial', 'initial', 50]}
                backgroundColor={['initial', 'initial', 'background']}
              />
            </GridItem>
          </Grid>
        </Section>
      </ContainerFixed>
    </>
  )
}

export default AboutUs

import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'

import Home from '../components/Home'
import { GlobalPageContext } from '../types'
import { HomeQuery } from '../../graphql-types'
import PageContainer from '../components/PageContainer'
import createPageContainerProps from '../createPageContainerProps'

type Props = PageProps<HomeQuery, GlobalPageContext>

const HomePage: FC<Props> = ({ data, pageContext }) => {
  const { seoTranslations, commonTranslations, homeTranslations } = data
  const t = { ...seoTranslations!.json!, ...commonTranslations!.json!, ...homeTranslations!.json! }

  return (
    <PageContainer
      translations={t}
      {...createPageContainerProps(data, pageContext)}
    >
      <Home
        workPagePath={data.workPage?.path!}
        aboutPagePath={data.aboutPage?.path!}
        servicesPagePath={data.servicesPage?.path!}
      />
    </PageContainer>
  )
}

export default HomePage

export const pageQuery = graphql`
  query Home($pathRegex: String!, $lang: String!, $pageId: String!) {
    ...PageData

    homeTranslations: file(
      name: { regex: $pathRegex }
      extension: { eq: "json" }
      absolutePath: { regex: "/translations/" }
    ) {
      json: childTranslationsJson {
        hero {
          valueProposition
          ctaLabel
        }
        section {
          aboutUs {
            title
            content
            ctaLabel
            ctaSrText
          }
          services {
            title
            ctaLabel
            ctaSrText
            discover {
              title
              description
            }
            design {
              title
              description
            }
            build {
              title
              description
            }
            scale {
              title
              description
            }
          }
        }
      }
    }

    workPage: sitePage(
      context: {
        lang: {
          eq: $lang
        }
        pageId: {
          eq: "work"
        }
      }
    ) {
      path
    }

    aboutPage: sitePage(
      context: {
        lang: {
          eq: $lang
        }
        pageId: {
          eq: "about"
        }
      }
    ) {
      path
    }

    servicesPage: sitePage(
      context: {
        lang: {
          eq: $lang
        }
        pageId: {
          eq: "services"
        }
      }
    ) {
      path
    }
  }
`

import React, { FC, ComponentProps as CP } from 'react'
import Box from './Box'

type Props = CP<typeof Box>

const Section: FC<Props> = ({ css, ...props }) => (
  <Box
    pt={4}
    pb={4}
    as="section"
    {...props}
  />
)

export default Section

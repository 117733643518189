import React, { FC, ComponentProps as CP } from 'react'
import { Heading } from 'rebass'

type Props = CP<typeof Heading>

const SectionHeading: FC<Props> = ({ css, ...props }) => (
  <Heading
    as="h2"
    my={4}
    fontSize={5}
    {...props}
  />
)

export default SectionHeading

import React, { FC, Fragment, useRef, useLayoutEffect } from 'react'
import useInView from 'react-cool-inview'
import { Text, Flex, Box } from 'rebass'

import Link from '../../../../ui/Link'
import Grid from '../../../../ui/Grid'
import SrOnly from '../../../../ui/SrOnly'
import GridItem from '../../../../ui/GridItem'
import Section from '../../../../ui/Section'
import useIntl from '../../../../states/useIntl'
import { CompactCTAButton } from '../../../CTAButton'
import SectionHeading from '../../../../ui/SectionHeading'
import ContainerFixed from '../../../../ui/ContainerFixed'

import animationDiscover from './assets/discover_icon.json'
import animationDesign from './assets/design_icon.json'
import animationBuild from './assets/build_icon.json'
import animationScale from './assets/scale_icon.json'

type Props = {
  servicesPagePath: string
}

const Services: FC<Props> = ({ servicesPagePath }) => {
  const { t } = useIntl()
  const servicesRef = useRef([
    {
      id: 'discover',
      title: t('section.services.discover.title'),
      description: t('section.services.discover.description'),
      animationData: animationDiscover,
      iconRef: null,
    },
    {
      id: 'design',
      title: t('section.services.design.title'),
      description: t('section.services.design.description'),
      animationData: animationDesign,
      iconRef: null,
    },
    {
      id: 'build',
      title: t('section.services.build.title'),
      description: t('section.services.build.description'),
      animationData: animationBuild,
      iconRef: null,
    },
    {
      id: 'scale',
      title: t('section.services.scale.title'),
      description: t('section.services.scale.description'),
      animationData: animationScale,
      iconRef: null,
    },
  ])

  const ref = useRef<HTMLDivElement | null>(null)
  useInView({
    ref,
    unobserveOnEnter: true,
    onEnter: async () => {
      const { default: lottie } = await import('lottie-web')
      servicesRef.current.forEach((service, i) => {
        window.setTimeout(() => {
          lottie.loadAnimation({
            container: service.iconRef!,
            animationData: service.animationData,
            renderer: 'svg',
            loop: false,
            autoplay: true,
          })
        }, 500 * i)
      })
    },
  })

  useLayoutEffect(() => { import('lottie-web') }, [])

  return (
    <div ref={ref}>
      <ContainerFixed>
        <Section>
          <SectionHeading color="text">
            {t('section.services.title')}
          </SectionHeading>

          <Grid mb={[0, 0, 6]}>
            {servicesRef.current.map((service, i) => {
              const { title, description } = service

              return (
                <Fragment key={i}>
                  <GridItem
                    width={[1, 1, 1 / 4]}
                    order={[0, 0, i]}
                  >
                    {i > 0 && (
                      <Box
                        display={['block', 'block', 'none']}
                        sx={{
                          borderTopStyle: 'solid',
                          borderTopWidth: '1px',
                          borderColor: 'divider',
                        }}
                        mt={[5, 5, 0]}
                        pt={[4, 4, 0]}
                      />
                    )}

                    <Flex
                      flexDirection={['row-reverse', 'row-reverse', 'column']}
                      alignItems={['flex-end', 'flex-end', 'flex-start']}
                    >
                      <Box
                        mb={3}
                        width={150}
                        height={150}
                        ref={el => service.iconRef = el}
                      />

                      <Text
                        color="text"
                        fontSize={4}
                        fontWeight="bold"
                        flex={1}
                      >
                        {title}
                      </Text>
                    </Flex>

                    <Text
                      color="text"
                      fontSize={0}
                    >
                      {description}
                    </Text>
                  </GridItem>

                  <GridItem
                    width={[1, 1, 1 / 4]}
                    order={[0, 0, i + 4]}
                  >
                    <Link to={`${servicesPagePath}#${service.id}`}>
                      <CompactCTAButton
                        containerProps={{ mt: 3 }}
                        as="div"
                      >
                        {t('section.services.ctaLabel')}
                        <SrOnly>
                          &nbsp;{t('section.services.ctaSrText')}
                        </SrOnly>
                      </CompactCTAButton>
                    </Link>
                  </GridItem>
                </Fragment>
              )
            })}
          </Grid>
        </Section>
      </ContainerFixed>
    </div>
  )
}

export default Services

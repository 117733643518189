import React, { FC, ComponentProps as CP } from 'react'

import { Hero, AboutUs, Services } from './components'

type Props = CP<typeof Hero> & CP<typeof AboutUs> & CP<typeof Services>

const Home: FC<Props> = ({ workPagePath, aboutPagePath, servicesPagePath }) => {
  return (
    <>
      <Hero workPagePath={workPagePath} />
      <AboutUs aboutPagePath={aboutPagePath} />
      <Services servicesPagePath={servicesPagePath} />
    </>
  )
}

export default Home
